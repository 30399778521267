import React, { useState } from "react";

import { resumes } from "./";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Resume = () => {
  const [resume, setResume] = useState(resumes.Main);

  const handleChange = (event) => {
    setResume(event.target.value);
  };
  return (
    <>
      <Box className="Resume-selector" sx={{ minWidth: 120 }}>
        <FormControl variant="standard" fullWidth>
          <InputLabel>Resume</InputLabel>
          <Select value={resume} label="Resume" onChange={handleChange}>
            <MenuItem value={resumes.Main}>Main</MenuItem>
            <MenuItem value={resumes.Extended}>Extended</MenuItem>
            <MenuItem value={resumes.Recruiter}>Recruiter</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <object
        className="Resume"
        data={resume.resume}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          <a href={resume.link}>Jonathan Yeh Resume</a>
        </p>
      </object>
    </>
  );
};

export default Resume;
