import React, { useContext } from "react";

import { FirebaseContext } from "../context";

import { Instagram, LinkedIn, GitHub } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const Socials = () => {
  const socials = useContext(FirebaseContext).socials;

  const socialsDispatch = (link) => {
    switch (link) {
      case "LinkedIn":
        return <LinkedIn fontSize="large" />;
      case "Instagram":
        return <Instagram fontSize="large" />;
      default:
        return <GitHub fontSize="large" />;
    }
  };

  const socialsMap = () => {
    let map = [];
    for (const [name, link] of Object.entries(socials)) {
      map.push(
        <IconButton key={name} href={link} target="_blank">
          {socialsDispatch(name)}
        </IconButton>
      );
    }

    return map;
  };

  return <>{socialsMap()}</>;
};

export default Socials;
