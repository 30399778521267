import Loader from "./Loader";
import "./App.css";

function App() {
  // https://codepen.io/escapetomars/pen/EeLmpp
  const slowScrollBackground = () => {
    const s = document.getElementById("background");
    const yPos = window.scrollY / 8;
    s.style.top = 50 + yPos + "%";
  };
  return (
    <div className="App">
      <div className="App-background-1" />
      <Loader />
    </div>
  );
}

export default App;
