import React from "react";

import { NavigatorButton } from "./";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircleIcon from "@mui/icons-material/Circle";

/**
 * @param {Array} links - Array of link objects with a name and ref property.
 * @param {Function} dispatch - Function to dispatch a ref to the router.
 */
const Navigator = (props) => {
  const { links, dispatch } = props;

  return (
    <div className="Navigator">
      {links.map((link, index) => {
        let icon = undefined;
        let fontSize = 1;
        if (index === 0) {
          icon = ArrowDropUpIcon;
          fontSize = 3.5;
        } else if (index === links.length - 1) {
          icon = ArrowDropDownIcon;
          fontSize = 3.5;
        } else {
          icon = CircleIcon;
          fontSize = 1.1;
        }
        return (
          <NavigatorButton
            id={link.ref}
            linkInfo={link}
            fontSizeStart={fontSize}
            Icon={icon}
            dispatch={dispatch}
          />
        );
      })}
    </div>
  );
};

export default Navigator;
