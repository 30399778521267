import React, { useContext } from "react";

import { preprocess } from "../../../assets/utils";

import { FirebaseContext } from "../../../context";

const About = (props) => {
  const { forwardedRef } = props;
  let data = preprocess(useContext(FirebaseContext).paragraphs.about);

  const aboutOpacityFunction = () => {
    const el = document.getElementById("About");
    const innerHeight = window.innerHeight;
    const pageTop = el?.getBoundingClientRect().top;
    const pageBottom = el?.getBoundingClientRect().bottom;
    const percentageFromTop = (pageTop / innerHeight) * 100;
    const percentageFromBottom = (pageBottom / innerHeight) * 100;
    if (percentageFromTop > 15 && percentageFromBottom < 95) {
      return 100;
    } else if (percentageFromTop <= 15) {
      return (percentageFromTop + 30) / 15;
    } else if (percentageFromBottom >= 95) {
      return 95 / (percentageFromBottom + 20);
    }
    return 0;
  };

  return (
    <div
      className="About"
      id="About"
      style={{ opacity: aboutOpacityFunction() }}
    >
      <h2 className="About-title" ref={forwardedRef}>
        About
      </h2>
      {data.map((paragraph, i) => (
        <div key={i} id={`About-text${i}`} className="About-text-container">
          <p className="About-text">{paragraph}</p>
          <br></br>
        </div>
      ))}
    </div>
  );
};

export default About;
