import Resume from "./Resume";
import mainResume from "./Yeh_Jonathan_Resume.pdf";
import masterResume from "./Yeh_Jonathan_Resume_Master.pdf";
import UIResume from "./Yeh_Jonathan_Resume_UI.pdf";

const resumes = {
  Main: {
    resume: mainResume,
    link: "https://drive.google.com/file/d/1BbqbMj7i6_y4sFCQlVCuiV8on29LLrfP/view?usp=sharing",
  },
  Extended: {
    resume: masterResume,
    link: "https://drive.google.com/file/d/1TNiORVgswfxaTPE15lao6f_fxeY9YK3g/view?usp=sharing",
  },
  Recruiter: {
    resume: UIResume,
    link: "https://drive.google.com/file/d/1acT6PzU_kYZrCRnDwD335V8YgvEPvfAs/view?usp=sharing",
  },
};

export { Resume, resumes };
