import React from "react";

import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();

  const handleClickDispatch = (e) => {
    e.preventDefault();
    const id = e.currentTarget.id;
    switch (id) {
      case "Header-Home":
        navigate("/");
        break;
      case "Header-Portfolio":
        navigate("/portfolio");
        break;
      default:
        navigate("/resume");
    }
  };

  return (
    <div className="Header">
      <div className="Header-container" id="Header-container">
        <h4
          id="Header-Home"
          className="Header-link"
          onClick={handleClickDispatch}
        >
          Home
        </h4>

        <h4
          id="Header-Portfolio"
          className="Header-link"
          onClick={handleClickDispatch}
        >
          Portfolio
        </h4>

        <h4
          id="Header-Resume"
          className="Header-link"
          onClick={handleClickDispatch}
        >
          Resume
        </h4>
      </div>
    </div>
  );
};

export default Header;
