import React, { useContext } from "react";

import { FirebaseContext } from "../context";
import { ProjectCard } from "../routes/portfolio/components";
import { stringToHTML } from "../assets/utils";

const ProjectList = (props) => {
  const { projectFilter } = props;
  const dataFetch = useContext(FirebaseContext);
  const projects = dataFetch.projects.data;
  const images = dataFetch.images;

  return (
    <div className="Portfolio-projects">
      {projectFilter
        ? projects.filter((project) => projectFilter(project))
        : projects.map((project) => {
            const description = stringToHTML(project.description);
            return (
              <ProjectCard
                key={project.id}
                name={project.name}
                link={project.link}
                description={description}
                image={images[project.id]}
              />
            );
          })}
    </div>
  );
};

export default ProjectList;
