import React from "react";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import assets from "../assets/assets.json";

const NavigatorButton = (props) => {
  const { linkInfo, dispatch, title, fontSizeStart, Icon } = props;
  const color = assets.colors.white;

  const name = linkInfo.name;
  const refTitle = linkInfo.ref;

  const SmallerIconButton = styled(IconButton)({
    maxWidth: "25%",
    transitionDuration: "2s",
    "&:hover": {
      backgroundColor: "transparent",
    },
  });

  const StyledIcon = styled(Icon)({
    color,
    transitionDuration: "2s",
    fontSize: `${fontSizeStart}em`,
    "&:hover": {
      fontSize: `${fontSizeStart * 1.2}em`,
    },
  });

  const handleClickDispatch = () => {
    dispatch(refTitle);
  };

  return (
    <div title={name} className="NavigatorButton-wrapper">
      <SmallerIconButton
        title={title}
        className="NavigatorButton"
        onClick={handleClickDispatch}
      >
        <StyledIcon />
      </SmallerIconButton>
    </div>
  );
};

export default NavigatorButton;
