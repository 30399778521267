import React from "react";

import { useNavigate } from "react-router-dom";

import profile from "../../../assets/profile.jpeg";
import { useOpacity } from "../../../assets/utils";

import { Socials } from "../../../components";
import { ColorButton } from "../../../components";

const Intro = (props) => {
  const { forwardedRef } = props;
  const navigate = useNavigate();

  const PortfolioButton = ColorButton({ width: "40%" });

  const introOffsetFunction = (el) => {
    return el?.offsetTop + el?.offsetHeight / 3;
  };

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/portfolio");
  };
  return (
    <div className="Intro">
      <div
        className="Intro-container"
        id="Intro-container"
        style={{
          opacity: useOpacity("Intro-container", introOffsetFunction),
        }}
      >
        <div className="Intro-profile-container">
          <img
            className="Intro-profile"
            src={profile}
            alt="Jonathan Yeh profile"
          />
        </div>
        <h1 className="Intro-name" ref={forwardedRef}>
          Jonathan Yeh
        </h1>
        <h2 className="Intro-position">Full Stack Developer</h2>
        <div className="Intro-socials">
          <Socials />
        </div>
        <PortfolioButton
          className="Intro-portfolio-button"
          onClick={handleClick}
        >
          <p className="Intro-portfolio-button-text">Check out my Projects</p>
        </PortfolioButton>
      </div>
    </div>
  );
};

export default Intro;
