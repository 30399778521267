// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB3wyjvZbBDN4zYB0pgGhgPOzL2S7aBY58",
  authDomain: "portfolio-d130a.firebaseapp.com",
  projectId: "portfolio-d130a",
  storageBucket: "portfolio-d130a.appspot.com",
  messagingSenderId: "838015785664",
  appId: "1:838015785664:web:d4e69917a943b867af6b25",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, db, auth, storage };
