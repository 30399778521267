import assets from "../assets/assets.json";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const colors = assets.colors;

const ColorButton = (style) => {
  const originalStyle = {
    height: "45px",
    borderRadius: "15px",
    color: colors.white,
    backgroundColor: colors.blue,
    "&:hover": {
      backgroundColor: colors.darkBlue,
    },
  };
  const newStyle = { ...originalStyle, ...style };
  const CustomButton = styled(Button)(newStyle);
  return CustomButton;
};

export default ColorButton;
