import React, { useEffect, useState, useRef } from "react";

import { FirebaseContext, WindowContext } from "./context";

import { db, storage } from "./firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import assets from "./assets/assets.json";

import { LoadingScreen, Header } from "./components";
import Main from "./routes/main";
import Portfolio from "./routes/portfolio";
import { Resume } from "./routes/resume";

const Loader = () => {
  const [data, setData] = useState({});
  const [windowData, setWindowData] = useState(window.scrollY);

  const [loading, setLoading] = useState(true);

  // credits to https://thewebdev.info/2021/09/05/how-to-get-scroll-position-with-react/
  const handleScroll = () => {
    const scrollY = window.scrollY;
    setWindowData({ scrollY });
  };

  useEffect(() => {
    const getData = async () => {
      const querySnap = await getDocs(collection(db, "assets"));
      const dataFetch = {};
      querySnap.forEach((doc) => {
        dataFetch[doc.id] = doc.data();
      });
      dataFetch.images = {};
      for (const project of dataFetch.projects.data) {
        const downloadURL = await getDownloadURL(
          ref(storage, `images/${project.id}.jpg`)
        );
        const portfolioImage = (
          <img
            className="ProjectCard-image"
            id={project.id}
            src={downloadURL}
            alt={project.name}
          />
        );
        dataFetch.images[project.id] = portfolioImage;
      }

      setData(dataFetch);
      setLoading(false);
    };

    getData();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <FirebaseContext.Provider value={data}>
      <WindowContext.Provider value={windowData}>
        <Router>
          <div onScroll={handleScroll}>
            <Header color={assets.colors.blue} />
            <Routes>
              <Route exact path="/" element={<Main />} />
              <Route exact path="/resume" element={<Resume />} />
              <Route exact path="/portfolio" element={<Portfolio />} />
            </Routes>
          </div>
        </Router>
      </WindowContext.Provider>
    </FirebaseContext.Provider>
  );
};

export default Loader;
