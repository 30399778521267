import React, { useRef } from "react";

import assets from "../../assets/assets.json";
import background from "../../assets/background.svg";
import { Intro, About, ProjectList } from "./components";
import { Navigator } from "../../components";

const Main = (props) => {
  const aboutRef = useRef(null);
  const introRef = useRef(null);
  const projectListRef = useRef(null);
  const links = assets.mainLinks;

  const scrollDispatch = (ref) => {
    let scrollRef = undefined;
    switch (ref) {
      case "Main-Intro":
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      case "Main-About":
        scrollRef = aboutRef;
        break;
      default:
        scrollRef = projectListRef;
        break;
    }
    scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <>
      <Navigator links={links} dispatch={scrollDispatch} />
      <div className="Main-section-one">
        <Intro />
        <img
          ref={introRef}
          className="background"
          src={background}
          alt="wave gradient"
        />
      </div>
      <div className="Main-section-two">
        <About forwardedRef={aboutRef} />
        <h2 ref={projectListRef}>Projects</h2>
        <ProjectList />
      </div>
    </>
  );
};

export default Main;
