import React, { useContext } from "react";

import { ProjectCard } from "./components";
import { Header } from "../../components";

import { stringToHTML } from "../../assets/utils";
import { FirebaseContext } from "../../context";

const Portfolio = (props) => {
  const dataFetch = useContext(FirebaseContext);
  const projects = dataFetch.projects.data;
  const images = dataFetch.images;

  return (
    <>
      <Header />
      <div className="Portfolio">
        <div className="Portfolio-background" />
        <div className="Portfolio-projects">
          {projects.map((project) => {
            const description = stringToHTML(project.description);
            return (
              <ProjectCard
                key={project.id}
                name={project.name}
                link={project.link}
                description={description}
                image={images[project.id]}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Portfolio;
