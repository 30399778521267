import React from "react";

import { TailSpin } from "react-loader-spinner";

const LoadingScreen = () => {
  return (
    <div className="Loading">
      <div className="Loading-circle">
        <TailSpin color="#3333ee" ariaLabel="loading-indicator" />
      </div>
    </div>
  );
};

export default LoadingScreen;
