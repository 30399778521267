import React, { useContext } from "react";

import { WindowContext } from "../context";

const preprocess = (dataInput) => {
  return dataInput.split("<br>");
};

const stringToHTML = (string) => {
  let html = [];
  let currentString = "";
  for (let word of string.split(" ")) {
    switch (word) {
      case "<em>":
        html.push(currentString);
        currentString = "";
        break;
      case "</em>":
        html.push(<em key={`html-${html.length}`}>{currentString}</em>);
        currentString = "";
        break;
      default:
        currentString = currentString.concat(
          `${!!word.match(/^[.,:!?]/) ? "" : " "}${word}`
        );
    }
  }
  html.push(currentString);
  return html;
};

const useOpacity = (
  id,
  offsetFunction = (el) => {
    return el?.offsetTop;
  }
) => {
  const windowPosition = useContext(WindowContext).scrollY;
  const element = document.getElementById(id);
  const offset = offsetFunction(element);
  return `${offset - windowPosition}%`;
};

const handleScroll = (ref) => {
  ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
};

export { preprocess, stringToHTML, useOpacity, handleScroll };
