import React from "react";

const ProjectCard = (props) => {
  const { name, link, description, image } = props;
  return (
    <a target="__blank" className="ProjectCard-wrapper" href={link}>
      <div className="ProjectCard">
        <div className="ProjectCard-image">{image}</div>
        <div className="ProjectCard-name-wrapper">
          <h2 className="ProjectCard-name">{name}</h2>
        </div>
        <p className="ProjectCard-description">{description}</p>
      </div>
    </a>
  );
};

export default ProjectCard;
